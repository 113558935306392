import React, { PropsWithChildren } from 'react';
import { useTranslations } from '~/services/cms';
import { CommerceModel } from 'api-types';
import { isJPG } from 'utils';
import { textStyles, Text } from 'ui';

import styles from './Summary.module.css';
import clsx from 'clsx';

type Props = {
    sum: CommerceModel.Basket | CommerceModel.OrderReceipt;
};

export const Summary = ({ sum }: Props) => {
    const translate = useTranslations();

    const isJSX = (value?: JSX.Element | string): value is JSX.Element => {
        if (!value) {
            return false;
        }
        return (value as JSX.Element).props !== undefined;
    };

    const TableRow = ({
        children,
        value,
        discount,
    }: PropsWithChildren<{ value?: JSX.Element | string; discount?: boolean }>) => {
        // Don't render components without a value
        return (isJSX(value) && value.props.children) || value ? (
            <tr>
                <td className={clsx(styles.descriptionTableData)}>{children}</td>
                <td className={clsx(styles.summaryTableData, {
                    [styles.discount]: discount
                })}>{value}</td>
            </tr>
        ) : null;
    };

    return (
        <table className={clsx(
            textStyles.text,
            textStyles[`variant-body`],
            textStyles['as-table'],
            styles.summary
        )}>
            <tbody>
                {sum?.freightAmount && <>
                    <TableRow
                        children={translate('commerce.price.delivery')}
                        value={sum.freightAmount}
                    />
                    <tr>
                        <td colSpan={2}>
                            <div className={styles.summaryDivider} />
                        </td>
                    </tr>
                </>}

                {!isJPG() && (
                    <TableRow
                        children={
                            <Text variant='body' as="b" className={styles.summaryText}>
                                {translate('commerce.price.tax')}
                            </Text>
                        }
                        value={
                            <Text variant='body' as="b" className={styles.summaryText}>
                                {sum?.totalPrice?.displayTaxValue}
                            </Text>
                        }
                    />
                )}

                <TableRow
                    children={
                        <Text variant='body' as="b" className={styles.summaryText}>
                            {isJPG() ? translate('commerce.price.priceWithoutTax') : translate('commerce.price.priceWithTax')}
                        </Text>
                    }
                    value={
                        <Text variant='body' as="b" className={styles.summaryText}>
                            {sum?.totalPriceInclShipping}
                        </Text>
                    }
                />
            </tbody>
        </table>
    );
};
